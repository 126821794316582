<template>
  <div
    :tabindex="tabIndex"
    class="slide">
    <div>
      <h2> {{ title }}<span class="t-black"></span></h2>
      <p>
        {{ description1 }}
        <br><br>
        {{ description2 }}
        <br>
        {{ description3 }}
        <br><br>
        {{ description4 }}
        <br><br></p>
      <p> {{ description5 }} </p>
      <div style="text-align: center;">
      <img src="@/assets/img/01.png" @click="openModal(1)">
      <img src="@/assets/img/02.png" @click="openModal(2)">
      <img src="@/assets/img/03.png" @click="openModal(3)">
      <img src="@/assets/img/04.png" @click="openModal(4)">

      <div v-if="isVisible" class="modal" @click="close">
        <div class="modal-content">
          <span class="close" @click="close">&times;</span>
          <h2 class="modal-h2">{{ content.title }} </h2>
          <p> {{ content.description }} </p>
        </div>
      </div>

      </div>
    </div>
    <div class="disclaimer">
    </div>
    <ChangeSlide
      :actualSlide="actualSlide"
      :slideValue="slideValue"
      :direction="'up'"
      @keydown.enter="updateSlide('prev')"
      @keydown.space="updateSlide('prev')"
      @click="updateSlide('prev')"/>
    <ChangeSlide
      :actualSlide="actualSlide"
      :slideValue="slideValue"
      @click="updateSlide()"/>

  </div>
</template>

<script>
import slideMixin from '@/mixins/slideMethods'

export default {
  name: 'TTXSlides',
  mixins: [slideMixin],
  data () {
    return {
      age: new Date(Date.now() - new Date('11/1/17').getTime()).getFullYear() - 1970,
      imgList: [
        {
          src: require('@/assets/img/01.png'),
          link: '',
          title: ''
        },
        {
          src: require('@/assets/img/02.png'),
          link: '',
          title: ''
        },
        {
          src: require('@/assets/img/03.png'),
          link: '',
          title: ''
        },
        {
          src: require('@/assets/img/04.png'),
          link: '',
          title: ''
        }
      ],
      modalContent: {},
      isVisible: false,
      content: Object,
      content1: { title: 'Finance market', descriptionen: 'Conducting TTX in finance assesses response plans, fortifying readiness against cyber threats and financial risks.', descriptiones:'Conducting TTX in finance assesses response plans, fortifying readiness against cyber threats and financial risks.'},
      content2: { title: 'Critical Infrastructure', description: 'TTX for critical infrastructure evaluates response protocols, strengthening resilience against cyber threats and ensuring continuity.'},
      content3: { title: 'Supply Chain', description: 'During TTX on supply chain attacks, organizations simulate breaches to enhance response strategies and resilience.'},
      content4: { title: 'Ransomware Threats', description: 'In TTX focused on ransomware threats, teams simulate attacks, refining responses to mitigate risks effectively.'},
    }
  },
  computed: {
    tabIndex () {
      return this.actualSlide === this.slideValue ? '1' : '-1'
    },
    lang() {
      return this.$route.meta.lang;
    },
    title() {
      return this.lang === 'es' ? '¿Qué es un TTX?' : 'What is a TTX?';
    },
    description1() {
      return this.lang === 'es' ? 'Nuestro TTX permite evaluar tus capacidades de respuesta mediante un escenario simulado.' : 'Our TTX service allows you to assess your incident response capabilities through a simulated-scenario. ';
    },
    description2() {
      return this.lang === 'es' ? 'Personas | Procesos | Tecnologia' : 'Processes | People | Technology';
    },
    description3() {
      return this.lang === 'es' ? 'Ejecutivo | Estratégico | Técnico ' : 'Executive | Strategic | Technical ';
    },
    description4() {
      return this.lang === 'es' ? 'Los ejercicios presentan múltiples escenarios basados en experiencias del mundo real en un entorno de mesa redonda para observar las acciones y decisiones simuladas de una organización.' : 'During each exercise, multiple scenarios based on real-world experiences are presented in a round table environment to observe the organization simulated actions and decisions. Each scenario is designed for you and with you';
    },
    description5() {
      return this.lang === 'es' ? 'Cada escenario está diseñado para y con usted' : 'Some typical basics scenarios:';
    },
    popup1() {
      return this.lang === 'es' ? { title: 'Finance market', description: 'La realización de TTX en finanzas evalúa los planes de respuesta y refuerza la preparación frente a las ciberamenazas y los riesgos financieros.'} : { title: 'Finance market', description: 'Conducting TTX in finance assesses response plans, fortifying readiness against cyber threats and financial risks.'};
    },
    popup2() {
      return this.lang === 'es' ? { title: 'Critical Infrastructure', description: 'TTX para infraestructuras críticas evalúa los protocolos de respuesta, reforzando la resistencia frente a las ciberamenazas y garantizando la continuidad.'} : { title: 'Critical Infrastructure', description: 'TTX for critical infrastructure evaluates response protocols, strengthening resilience against cyber threats and ensuring continuity.'};
    },
    popup3() {
      return this.lang === 'es' ? { title: 'Supply Chain', description: 'Durante los TTX sobre ataques a la cadena de suministro, las organizaciones simulan infracciones para mejorar las estrategias de respuesta y la capacidad de recuperación.'} : { title: 'Supply Chain', description: 'During TTX on supply chain attacks, organizations simulate breaches to enhance response strategies and resilience.'};
    },
    popup4() {
      return this.lang === 'es' ? { title: 'Ransomware Threats', description: 'En el TTX centrado en las amenazas de ransomware, los equipos simulan ataques y perfeccionan las respuestas para mitigar los riesgos con eficacia.'} : { title: 'Ransomware Threats', description: 'In TTX focused on ransomware threats, teams simulate attacks, refining responses to mitigate risks effectively.'}
    }
  },
  methods: {
    openModal(expression) {
      switch(expression) {
        case 1:
          this.content = this.popup1
          break;
        case 2:
          this.content = this.popup2
          break;
        case 3:
          this.content = this.popup3
          break;
        case 4:
          this.content = this.popup4
          break;
        default:
          this.content = this.popup1
      }
      this.isVisible = true
    },
    close() {
      this.isVisible = false
      }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables';

img {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.65);
  margin: 1em 1em;
  transition: transform .3s ease;
  max-width: 10%;
}

.slide p {
  margin-bottom: 0;
  font-size: 1.2em;
}

.slide h3 {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;

  span {
    position: absolute;
    font-size: .4em;
    right: 12px;
  }
}

.image-slide {
  display: flex;

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
    text-decoration: none;

    img:hover + label::before {
      width: 100%;
    }

    label {
      margin-top: .7em;
      font-size: 1.2em;
      position: relative;
      padding: 0 3px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 0;
        background-color: var(--yellow);
        height: 100%;
        transition: width 0.3s cubic-bezier(.22,.68,0,1.2);
        z-index: -1;
      }

      &:hover {
        cursor: pointer;

        &::before {
          width: 100%;
        }
      }
    }
  }
}

p {
  margin-bottom: 0;
}

h3 {
  margin-top: 0;
}

h2 {
  font-size: 1.6em !important;
  max-width: 550px !important;
  margin: 0 auto !important;
  line-height: 1.4em;
}

.modal-h2 {
    margin: 0 auto;

  }

@media screen and (max-width: $tablet-breakpoint) {

  .slide {

    h2 {
      margin: 0 !important;
      font-size: 1.2em !important;
    }
    p {
      font-size: 1.2em !important;
    }
    .image-slide {
      max-width: 100%;
      flex-wrap: wrap;

      a {
        width: 100%;

        label {
          background-color: var(--yellow);
          font-weight: bold;
          padding: 5px;
          letter-spacing: 0.05em;

          &::before {
            display: none;
          }
        }
      }

      img {
        display: none;
      }
    }

    h3 {
      span {
        right: 8px;
      }
    }
  }

  .slide .arrow-container.arrow-down {
      bottom: 3.5em;
  }
}

.modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background-color: #404040;
    padding: 35px 10px 40px 10px;
    border: #FFFFFF;
    width: 80%; /* Responsive width */
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    position: relative; /* For positioning the close button absolutely within the modal */
  }
  .modal-h2 {
    margin: 0 ;
    text-align: left;
  }
  .close {
    color: #aaa;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    cursor: pointer;
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .modal-content {
      width: 90%; /* Wider on smaller screens */
      padding: 35px 10px 40px 10px;
    }
  
    h2 {
      font-size: 1.2em !important;  
    }
    p {
      font-size: 1.2em !important;
    }
    .close {
      font-size: 24px; /* Slightly smaller close button */
    }

    img {
      max-width: 30%;
    }
  }
</style>
    
  