import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import gsap from 'gsap'
import VueGtag from 'vue-gtag'
import 'normalize.css'
import '@/assets/scss/styles.scss'

import ChangeSlide from '@/components/Global/ChangeSlide'


const app = createApp(App)
app.use(router)
app.use(VueGtag, {
  config: { id: 'G-064JZPN5RQ' }
})
app.provide('gsap', gsap)
app.component('ChangeSlide', ChangeSlide)
app.mount('#app')
