<template>
    <div class="d-flex footer-container">
      <div class="d-flex container">
        <div class="d-flex footer-sign">
          <div style="color: #f2f2f3">
            © BASE4 Security {{ year }}
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'ContentFooter',
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped lang="scss">
.footer-container {
  min-height: var(--menu-height);
}

span {
  white-space: nowrap;
}

.container {
  justify-content: space-between;
  align-items: center;
}
</style>
