<template>
  <div
    :tabindex="tabIndex"
    class="slide b-light-black">
    <div v-if="lang !== 'es'">
      <h2> TTX Quiz <span class="t-black">.</span></h2>
      <div id="QuestionContainer" v-html="stepText" >
        </div>
      <div class="quizcontainer"  v-if="step < 9">
        <div v-for="(option, index) in currentOptions" :key="index"  >
          <label :for="option.value" v-if="step !== 1 && step !== 8"> 
            <input type="radio" :id="option.value" :value="option.value" v-model="selectedOption" style="margin-right:5px;" v-if="step !== 1 && step !== 8">
            {{ option.text }}
          </label>
        </div>

        <div class="form-container" v-if="step == 2">
          <div class="form-group">
            <label for="name">Name:</label>
            <input type="text" id="name" v-model="formData.name" required >
          </div>
          <div class="form-group">
              <label for="email">Business Email:</label>
              <input type="email" id="email" v-model="formData.email" required >
              <label class="privacy" ><input type="checkbox" id="privacy" /> I accept this <a href="https://www.base4sec.com/privacy-policy/" target=”_blank” >privacy policy</a>
              </label>

          </div>
        </div>

        <button v-if="step == 8" class="button" @click="nextStep">
          <label >Finish</label>
        </button>

        <button v-if="step !== 8" class="button" @click="nextStep" >
          <label v-if="step == 1">Start</label>
          <label v-if="step !== 1">Send</label>
        </button>

      </div>
    </div>
    <div v-if="lang === 'es'">
      <h2> TTX Quiz <span class="t-black">.</span></h2>
      <div id="QuestionContainer" v-html="stepTextEs" >
        </div>
      <div class="quizcontainer"  v-if="step < 9">
        <div v-for="(option, index) in currentOptions" :key="index"  >
          <label :for="option.value" v-if="step !== 1 && step !== 2 && step !== 8"> 
            <input type="radio" :id="option.value" :value="option.value" v-model="selectedOption" style="margin-right:5px;" v-if="step !== 1 && step !== 8">
            {{ option.text }}
          </label>
        </div>

        <div class="form-container" v-if="step == 2">
          <div class="form-group">
            <label for="name">Nombre:</label>
            <input type="text" id="name" v-model="formData.name" required >
          </div>
          <div class="form-group">
              <label for="email">Email laboral:</label>
              <input type="email" id="email" v-model="formData.email" required >
              <label class="privacy" ><input type="checkbox" id="privacy" /> Acepto esta <a href="https://www.base4sec.com/privacy-policy/" target=”_blank” >politica de privacidad</a>
              </label>

          </div>
        </div>

        <button v-if="step == 8" class="button" @click="nextStep">
          <label >Finalizar</label>
        </button>

        <button v-if="step !== 8" class="button" @click="nextStep" >
          <label v-if="step == 1">Iniciar</label>
          <label v-if="step !== 1">Enviar</label>
        </button>

      </div>
    </div>

    <ChangeSlide
      :actualSlide="actualSlide"
      :slideValue="slideValue"
      :direction="'up'"
      @keydown.enter="updateSlide('prev')"
      @keydown.space="updateSlide('prev')"
      @click="updateSlide('prev')"/>
    <ChangeSlide
      :actualSlide="actualSlide"
      :slideValue="slideValue"
      @click="updateSlide()"/>
  </div>
</template>

<script>
import slideMixin from '@/mixins/slideMethods'
import axios from 'axios';

export default {
  name: 'QuizSlide',
  mixins: [slideMixin],
  data () {
    return {
      leftIcon: '⚠️',
      rightIcon: '🧑‍💻',
      sitekey: '6Ldlkb4pAAAAAJWokhqBpVnnpWygVr7JxNHEQVTd',
      step: 1,
      totalSteps: 7,
      stepTexts: [
        // Intro
        '<p>Welcome to our self-guided TTX, simulate an incident with us but keep in mind that this is just a game, '+
        'a real TTX is planned  with our team in a collaborative project, CTI-driven with specific goals and scenarios. <br><br>'+
        'People in our simulations really feel the incident as REAL. <br>'+
        ''+
        '</p><br>'+
        '<p style="margin-bottom: 1em !important;"> <b> Ready? </b> </p>',
        // Step 0 - Form
        '',
        // Step 1
        '<p> You are the CISO of Electracorp, the leading company responsible for electricity generation. <br><br>'+ 
        "It's Monday morning, as you settle into your office, sipping on a cup of coffee, "+
        'an urgent alert blares from the monitored system. <br>'+
        'Your heart quickens as you read the message from the AV: <br> <b>"Ransomware activity detected on a System."</b> '+  
        '</p>'+
        '<p style="margin-bottom: 1em !important;" class="b-light-black"> <b>What do you do? (1/5)</b> </p>',
        // Step 2
        '<p> Your mind races as you consider the implications of the alert. <br>'+ 
        'It seems that the malware encrypted all system files. <br><br>'+
        'Your team needs your decision for the next step.<br>'+ 
        '</p>'+
        '<p style="margin-bottom: 1em !important;" class="b-light-black"> <b>What do you do? (2/5)</b> </p> ',
        // Step 3
        '<p>During the investigation the team determines that there'+ 
        ' has been unauthorized access to sensitive data.<br>'+
        'Yes, its worst that you think!<br>'+
        'The sensitive data its not yours, its from your customers <br>'+
        'There is no approved Playbook to follow.'+
        '</p><br><br>'+
        '<p style="margin-bottom: 1em !important;" class="b-light-black"> <b>What do you do? (3/5)</b> </p> ',
        // Step 4
        '<p> Despite your efforts, the situation appears to be escalating. <br>'+ 
        'You receive reports of unusual network behavior from multiple departments.'+
        '</p><br><br>'+
        '<p style="margin-bottom: 1em !important;" class="b-light-black"> <b>What do you do? (4/5)</b> </p> ',
        // Step 5
        '<p> As tensions rise, each team begins to follow up on the incident and service is restored, '+
        'it seems that production is back to normal until the afternoon brings bad news.<br><br>'+
        'One of your databases was leaked on a Darkweb forum. @Groundworm attack again! <br>'+
        ' </p>' +
        '</p><br><br>'+ 
        '<p style="margin-bottom: 1em !important;" class="b-light-black"> <b>What do you do? (5/5)</b> </p>',
        // Last Message
        '<p> Well done! '+
        'Thanks to your quick thinking, operations have now gone back to normal and there have been no new impacts. '+
        'Register to receive a model report on how your organization can act against threats. You will also be entered into a drawing for great prizes.</p>',
        '<p> Thanks for participating  </p> '
        ],
      stepTextsEs: [
        // Intro
        '<p>Bienvenido a nuestro TTX autoguiado! Simule un incidente con nosotros/as, es importante aclarar que, esto es un juego, '+
        'un TTX real está planeado por nuestros equipos, en un proyecto colaborativo con objetivos y escenarios específicos. <br><br>'+
        'Al finalizar recibirás en tu correo un informe modelo sobre cómo tu organización puede actuar contra las amenazas y participarán de un sorteo que se realizará en el stand el último día del evento. <br>'+
        ''+
        '</p><br>'+
        '<p style="margin-bottom: 1em !important;"> <b> ¿Listo? </b> </p>',
        // Step 0 - Form
        '',
        // Step 1
        '<p> Eres el CISO de Electracorp, la empresa líder responsable de la generación de electricidad. <br><br>'+ 
        "Es lunes por la mañana, mientras te instalas en tu oficina, tomando un café, "+
        'una alerta urgente suena desde el sistema monitorizado. <br>'+
        'Tu corazón se acelera al leer el mensaje del AV: <br> <b>"Actividad de ransomware detectada en un sistema."</b> '+  
        '</p>'+
        '<p style="margin-bottom: 1em !important;" class="b-light-black"> <b>¿Qué harías? (1/5)</b> </p>',
        // Step 2
        '<p> Tu mente se acelera mientras consideras las implicaciones de la alerta. <br>'+ 
        'Parece que el malware ha cifrado todos los archivos del sistema. <br><br>'+
        'Tu equipo necesita tu decisión para el siguiente paso.<br>'+ 
        '</p>'+
        '<p style="margin-bottom: 1em !important;" class="b-light-black"> <b>¿Qué harías? (2/5)</b> </p> ',
        // Step 3
        '<p>Durante la investigación, el equipo determina que ha habido acceso no autorizado a datos sensibles.<br>'+
        '¡Sí, es peor de lo que piensas!<br>'+
        'Los datos sensibles no son tuyos, son de tus clientes. <br>'+
        'No hay un Playbook aprobado para seguir.'+
        '</p><br><br>'+
        '<p style="margin-bottom: 1em !important;" class="b-light-black"> <b>¿Qué harías? (3/5)</b> </p> ',
        // Step 4
        '<p> A pesar de tus esfuerzos, la situación parece estar escalando. <br>'+ 
        'Recibes informes de comportamiento inusual en la red de múltiples departamentos.'+
        '</p><br><br>'+
        '<p style="margin-bottom: 1em !important;" class="b-light-black"> <b>¿Qué harías? (4/5)</b> </p> ',
        // Step 5
        '<p> A medida que aumentan las tensiones, cada equipo comienza a dar seguimiento al incidente y se restaura el servicio, '+
        'parece que la producción ha vuelto a la normalidad hasta que la tarde trae malas noticias.<br><br>'+
        'Una de tus bases de datos fue filtrada en un foro de la Darkweb. ¡@Groundworm ataca de nuevo! <br>'+
        ' </p>' +
        '</p><br><br>'+ 
        '<p style="margin-bottom: 1em !important;" class="b-light-black"> <b>¿Qué harías? (5/5)</b> </p>',
        // Last Message
        '<p> ¡Bien hecho! '+
        'Gracias a tu rápida actuación, las operaciones han vuelto a la normalidad y no ha habido nuevos impactos. '+
        '</p>',
        '<p> Gracias por participar <br><br>'+
        ' Equipo BASE4 </p> '
        ],
      questions: [
        { text: "Pregunta 0", options: [
            { value: "Option1", text: "-" },
            { value: "Option2", text: "-" },
            { value: "Option3", text: "-" }
          ]
        },
        { text: "Pregunta 0", options: [
            { value: "Option1", text: "-" },
            { value: "Option2", text: "-" },
            { value: "Option3", text: "-" }
          ]
        },
        { text: "Pregunta 1?", options: [
            { value: "Option1", text: "Aislar los sistemas relacionados" },
            { value: "Option2", text: "Compartir la alerta con el equipo de IR para verificación" },
            { value: "Option3", text: "Notificar a las partes interesadas y autoridades" }
          ]
        },
        { text: "Pregunta 2?", options: [
            { value: "Option1", text: "Iniciar un escaneo completo del sistema" },
            { value: "Option2", text: "Seguir el Playbook de Ransomware" },
            { value: "Option3", text: "Implementar monitoreo del tráfico de la red" }
          ]
        },
        { text: "Pregunta 3?", options: [
            { value: "Option1", text: "Implementar 2FA en las cuentas de los clientes" },
            { value: "Option2", text: "Notificar el incidente a los clientes relacionados" },
            { value: "Option3", text: "Compartir la situación con un comité interno de incidentes" }
          ]
        },
        { text: "Pregunta 4?", options: [
            { value: "Option1", text: "Activar el equipo de respuesta a incidentes" },
            { value: "Option2", text: "Escalar el problema a la gestión ejecutiva" },
            { value: "Option3", text: "Solicitar inteligencia de amenazas a terceros en tu industria" },
          ]
        },
        { text: "Pregunta 5?", options: [
            { value: "Option1", text: "Definir un plan de comunicación para las partes interesadas externas" },
            { value: "Option2", text: "Analizar los datos para comprender el alcance de la exposición" },
            { value: "Option3", text: "Coordinar con agencias de cumplimiento de la ley para obtener asistencia" }
          ]
        },
        { text: "Question 6", options: [
            { value: "Option1", text: "Option" },
            { value: "Option2", text: "Option" },
            { value: "Option3", text: "Option" }
          ] 
        } 
      ],
      selectedOptionsCache: {},
      userInput: "",
      formData:{
        name: "",
        email: ""
      },
      formUrl:"https://docs.google.com/forms/d/e/1FAIpQLSeoTx3UPnNc2497yTjjz7KKGBVngRn8NUFUaN3bY8InZEmNGw/formResponse",
      formEntries: {
        name: 'entry.346964656',
        email: 'entry.1514068838',
        lang: 'entry.371794586'
      },
      prizeUrl:"https://docs.google.com/forms/d/e/1FAIpQLSfSSz0SWzJ7qjOC0Ez8csxyNayQy5et-_6Tn8VuDK8xgxKJuQ/formResponse",
      prizeEntries: {
        email: 'entry.403550611'
      }
    }
  },
  computed: {
    tabIndex () {
      return this.actualSlide === this.slideValue ? '1' : '-1'
    },
    stepText() {
      return this.stepTexts[this.step - 1];
    },
    stepTextEs() {
      return this.stepTextsEs[this.step - 1];
    },
    currentQuestion() {
      return this.questions[this.step - 1].text;
    },
    currentOptions() {
      return this.questions[this.step - 1].options;
    },
    selectedOption: {
      get() {
        return this.selectedOptionsCache[this.step] || "";
      },
      set(value) {
        this.selectedOptionsCache[this.step] = value;
      }
    },
    lang() {
      return this.$route.meta.lang;
    }
  },
  methods: {
    async nextStep() {  
      
      if (this.step > 2 && this.step < 7 ) {
      // Increment step counter
      var option1 = document.getElementById('Option1');
      var option2 = document.getElementById('Option2');
      var option3 = document.getElementById('Option3');

      if (option1.checked || option2.checked || option3.checked) {
          console.log("Option is checked.");
        } else {
          alert("You need to choose an option")
          return
        }
      }
          // Reset user input after storing it
      if (this.step == 2 ) {

        if (this.formData.name == "" || this.formData.email == "") {
          if (this.lang == "es") {
          alert("Completa tu nombre y mail para comenzar")
          } else {
            alert("Complete your name and email to start!")
          }
          return
        }
        
        var checkprivacy = document.getElementById('privacy');
        if (checkprivacy.checked) {
          console.log("Checkbox is checked.");
        } else {
          if (this.lang == "es") {
              alert("Necesitas aceptar la política de privacidad para participar")
            } else {
              alert("You need to accept the privacy policy to participate")
            }
          return
        }

        var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (emailPattern.test(this.formData.email)) {
        // Valid email
        } else {
            // Invalid email
            alert("Your email seeams to be invalid")
            return
        }

        const formPayload = {};
        formPayload[this.formEntries.name] = this.formData.name;
        formPayload[this.formEntries.email] = this.formData.email;
        if (this.lang == "es") {
          formPayload[this.formEntries.lang] = "es"
        } else {
          formPayload[this.formEntries.lang] = "en"
        }

        try {

          const responseform = await axios.post( this.formUrl, formPayload, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          });

          console.log('Data sent to form:', responseform.data);
          // Optionally, you can reset the form after sending the data
          // this.resetForm();

        } catch (error) {
          console.error('Error sending data to API:', error);
          //this.step = 1
        }

        const prizePayload = {};
        prizePayload[this.prizeEntries.email] = "TEST";

        try {
          // Function pending to check with internal team
          const responseprize = await axios.post( this.prizeUrl, prizePayload, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          });
          console.log('Registry:', responseprize.data);
        }  catch (error) {
          console.error('Error sending data to API:', error);
          // this.step = 1
        }
        // window.location.href = '/';
      }  

      this.step++;

    }

  },
  mounted() {
    // Retrieve user input from cache or local storage if it exists
    const storedOptions = localStorage.getItem("selectedOptionsCache");
    if (storedOptions) {
      this.selectedOptionsCache = JSON.parse(storedOptions);
    } 
  
  },
  watch: {
    selectedOptionsCache: {
      handler(val) {
        // Store selected options in cache whenever it's updated
        localStorage.setItem("selectedOptionsCache", JSON.stringify(val));
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';

.quizcontainer {
  text-align: left; 
  font-size: 1.6em;
  line-height: 1.7em; 
  padding: 0 12%;
  margin: 0 auto;
  max-width: 850px !important;
}

.slide p {
max-width: 750px;
font-size: 1.6em !important;
}

.slide h2 {
  font-size: 1.6em !important;
  max-width: 550px;
}

@media screen and (max-width: $tablet-breakpoint) {

  .slide p {
        max-width: 80%;
        font-size: 0.9em;
        padding: 0 0.5em;
        margin-bottom: 0;
      font-size: 1.2em !important;
    }

  .slide h2 {
    font-size: 1.2em !important;
  }

}
.quizcontainer {
  max-width: 100%;
  font-size: 1.2em;
  line-height: 1.3em;
}

.button {
  background-color: #404040;
  border-color: #FFFFFF;
  color: #f2f2f3;
  // background-color: #E1780B; 
  border-radius: 30px 30px 30px 30px;
  // border-color: #FFFFFF;
  //color: white;
  padding: 15px 32px;
  margin: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button:hover {
  border-color: #E1780B;
}

.form-container {
  max-width: 250px;
  margin: 0px auto;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  background: #00000000;
}

.form-group {
  margin-bottom: 20px;
}

a {
  color: #f2f2f3;
  background-color: transparent;
  text-decoration: underline;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.privacy {
  padding: 8px;
  font-size: 0.8em;
  text-align: right;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  color: #FFFFFF;
  background-color: #404040;
  border-radius: 4px;
  box-sizing: border-box; /* includes padding and border in width */
}

</style>
