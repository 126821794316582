<template>
  <div
    :tabindex="tabIndex"
    class="slide b-light-black">
    <div v-if="lang !== 'en'">
      <h2> Taller Simulación de Respuesta ante incidentes 2024 <span class="t-black">.</span></h2>
      <br>
      <div id="QuestionContainer" v-html="stepText" >
        </div>
      <div class="quizcontainer"  v-if="step < 9">

        <div class="form-container" id="containerteamname" style="display: block;">
          <div class="form-group">
            <label for="teamname">Nombre de Equipo:</label>
            <input type="text" id="teamname" >
          </div>
          <div class="form-group">
              <label class="teamready" ><input type="checkbox" id="teamready" /> Los roles están distribuidos estamos listos
              </label>
          </div>
        </div>

        <div class="form-container" id="containernotasevento1" style="display: none;">
          <div class="form-group">
            <label for="notasevento1">Registro para Evento #1:</label>
            <textarea rows="5" cols="50" id="notasevento1" ></textarea>
          </div>
        </div>

        <div class="form-container" id="containernotasevento2" style="display: none;">
          <div class="form-group">
            <label for="notasevento2">Registro para Evento #2:</label>
            <textarea rows="5" cols="50" id="notasevento2" ></textarea>
          </div>
        </div>

        <div class="form-container" id="containernotasevento3" style="display: none;">
          <div class="form-group">
            <label for="notasevento3">Registro para Evento #3:</label>
            <textarea rows="5" cols="50" id="notasevento3" ></textarea>
          </div>
        </div>

        <div class="form-container" id="containernotasevento4" style="display: none;">
          <div class="form-group">
            <label for="notasevento4">Registro para Evento #4:</label>
            <textarea rows="5" cols="50" id="notasevento4" ></textarea>
          </div>
        </div>

        <div class="form-container" id="containernotasevento5" style="display: none;">
          <div class="form-group">
            <label for="notasevento5">Registro para Evento #5:</label>
            <textarea rows="5" cols="50" id="notasevento5" ></textarea>
          </div>
        </div>

        <div v-if="step == 7">
          <br>
          <br>
          <br>
            <h2> Han llegado al final</h2> 
            <h2>¿Listo para enviar los registros?</h2>
          <br>
          <br>
          <br>
        </div>

        <div id="containercierre" style="display: none;">
          <br>
          <br>
          <br>
            <h2> Su respuesta a sido enviada </h2> 
            <h2> Gracias por participar </h2>
          <br>
          <br>
          <br>
        </div>

        <button v-if="step !== 7" class="button" @click="nextStep" >
          <label v-if="step == 1">Iniciar</label>
          <label v-if="step !== 1">Siguiente</label>
        </button>

        <button v-if="step == 7" class="button" @click="nextStep">
          <label >Enviar</label>
        </button>

      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SmallDynamic',
  data () {
    return {
      sitekey: '6Ldlkb4pAAAAAJWokhqBpVnnpWygVr7JxNHEQVTd',
      step: 1,
      totalSteps: 6,
      selectedNotesCache: {},
      userInput: "",
      formData:{
        name: "",
        email: ""
      },
      formUrl:"https://docs.google.com/forms/d/e/1FAIpQLScOMuc90OnX9eX3OREq2-PXtC87QI-kI0nNEsNhG9PWGczixQ/formResponse",
      formEntries: {
        teamname: 'entry.1334315474',
        evento1: 'entry.276641716',
        evento2: 'entry.611163463',
        evento3: ' entry.432046172',
        evento4: 'entry.1433028851',
        evento5: 'entry.567947210'
      }
    }
  },
  computed: {
    selectedNotes: {
      get() {
        return this.selectedNotesCache[this.step] || "";
      },
      set(value) {
        this.selectedNotesCache[this.step] = value;
      }
    },
    lang() {
      return this.$route.meta.lang;
    },
    special() {
      return this.$route.meta.special;
    }
  },
  methods: {
    async nextStep() {  
      
      var teamname
      var notasevento1
      var notasevento2
      var notasevento3
      var notasevento4
      var notasevento5
      
      if (this.step == 1) {
        teamname = document.getElementById("teamname").value
        var teamready = document.getElementById("teamready")

        if (teamname == "" || !teamready.checked) {
          alert("Completa el nombre de tu equipo y arma tu equipo")
          return
        }

        localStorage.setItem("teamname", teamname);
        document.getElementById("containerteamname").style.display = "none"
        document.getElementById("containernotasevento1").style.display = "block"


    }

    if (this.step == 2) {
        notasevento1 = document.getElementById("notasevento1").value

        if (notasevento1 == "" ) {
          alert("#1 Completa las notas para este evento")
          return
        }

        localStorage.setItem("notasevento1", notasevento1);

        document.getElementById("containernotasevento1").style.display = "none"
        document.getElementById("containernotasevento2").style.display = "block"

    }

    if (this.step == 3) {
        notasevento2 = document.getElementById("notasevento2").value

        if (notasevento2 == "" ) {
          alert("#2 Completa las notas para este evento")
          return
        }

        localStorage.setItem("notasevento2", notasevento2);
        
        document.getElementById("containernotasevento2").style.display = "none"
        document.getElementById("containernotasevento3").style.display = "block"

    }

    if (this.step == 4) {
        notasevento3 = document.getElementById("notasevento3").value

        if (notasevento3 == "" ) {
          alert("#3 Completa las notas para este evento")
          return
        }

        localStorage.setItem("notasevento3", notasevento3);

        document.getElementById("containernotasevento3").style.display = "none"
        document.getElementById("containernotasevento4").style.display = "block"

    }

    if (this.step == 5) {
        notasevento4 = document.getElementById("notasevento4").value

        if (notasevento4 == "" ) {
          alert("#4 Completa las notas para este evento")
          return
        }

        localStorage.setItem("notasevento4", notasevento4);

        document.getElementById("containernotasevento4").style.display = "none"
        document.getElementById("containernotasevento5").style.display = "block"

    }

    if (this.step == 6) {
        notasevento5 = document.getElementById("notasevento5").value

        if (notasevento5 == "" ) {
          alert("#5 Completa las notas para este evento")
          return
        }

        localStorage.setItem("notasevento5", notasevento5);

        document.getElementById("containernotasevento5").style.display = "none"

    }
          // Reset user input after storing it
      if (this.step == 7 ) {
        
        console.log("Iniciando Step 7")
        teamname = document.getElementById("teamname")
        console.log(teamname)
        teamname = document.getElementById("teamname").value
        console.log(teamname)

        notasevento1 = document.getElementById("notasevento1").value
        console.log(notasevento1)

        notasevento2 = document.getElementById("notasevento2").value
        notasevento3 = document.getElementById("notasevento3").value
        notasevento4 = document.getElementById("notasevento4").value
        notasevento5 = document.getElementById("notasevento5").value


        const formPayload = {};
        formPayload[this.formEntries.teamname] = teamname;
        formPayload[this.formEntries.evento1] = notasevento1;
        formPayload[this.formEntries.evento2] = notasevento2;
        formPayload[this.formEntries.evento3] = notasevento3;
        formPayload[this.formEntries.evento4] = notasevento4;
        formPayload[this.formEntries.evento5] = notasevento5;

        try {

          const responseform = await axios.post( this.formUrl, formPayload, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          });

          console.log('Data sent to form:', responseform.data);
          // Optionally, you can reset the form after sending the data
          // this.resetForm();

        } catch (error) {
          console.error('Error sending data to API:', error);
          //this.step = 1
        }

        document.getElementById("containercierre").style.display = "block"

      }  

      this.step++;

    }

  },
  mounted() {
    // Retrieve user input from cache or local storage if it exists
    const storedNotes = localStorage.getItem("selectedNotesCache");
    if (storedNotes) {
      this.selectedNotesCache = JSON.parse(storedNotes);
    } 

    var teamname = localStorage.getItem("teamname");
    document.getElementById("teamname").value = teamname;

    var notasevento1 = localStorage.getItem("notasevento1");
    document.getElementById("notasevento1").value = notasevento1
    var notasevento2 = localStorage.getItem("notasevento2");
    document.getElementById("notasevento2").value = notasevento2
    var notasevento3 = localStorage.getItem("notasevento3");
    document.getElementById("notasevento3").value = notasevento3
    var notasevento4 = localStorage.getItem("notasevento4");
    document.getElementById("notasevento4").value = notasevento4
    var notasevento5 = localStorage.getItem("notasevento5");
    document.getElementById("notasevento5").value = notasevento5
  
  },
  watch: {
    selectedNotesCache: {
      handler(val) {
        // Store selected options in cache whenever it's updated
        localStorage.setItem("selectedNotesCache", JSON.stringify(val));
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';

.quizcontainer {
  text-align: left; 
  font-size: 1.6em;
  line-height: 1.7em; 
  padding: 0 12%;
  margin: 0 auto;
  max-width: 850px !important;
}

.slide p {
max-width: 750px;
font-size: 1.6em !important;
}

.slide h2 {
  font-size: 1.6em !important;
  max-width: 550px;
}

@media screen and (max-width: $tablet-breakpoint) {

  .slide p {
        max-width: 80%;
        font-size: 0.9em;
        padding: 0 0.5em;
        margin-bottom: 0;
      font-size: 1.2em !important;
    }

  .slide h2 {
    font-size: 1.2em !important;
  }

}
.quizcontainer {
  max-width: 100%;
  font-size: 1.2em;
  line-height: 1.3em;
}

.button {
  background-color: #404040;
  border-color: #FFFFFF;
  color: #f2f2f3;
  // background-color: #E1780B; 
  border-radius: 30px 30px 30px 30px;
  // border-color: #FFFFFF;
  //color: white;
  padding: 15px 32px;
  margin: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button:hover {
  border-color: #E1780B;
}

.form-container {
  max-width: 90%;
  margin: 0px auto;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  background: #00000000;
}

.form-group {
  margin-bottom: 20px;
}

a {
  color: #f2f2f3;
  background-color: transparent;
  text-decoration: underline;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.privacy {
  padding: 8px;
  font-size: 0.8em;
  text-align: right;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  color: #FFFFFF;
  background-color: #404040;
  border-radius: 4px;
  box-sizing: border-box; /* includes padding and border in width */
}

</style>
