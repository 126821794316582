<template>
  <BaseLayout />
</template>

<script>
import BaseLayout from '@/components/BaseLayout'

export default {
  name: 'App',
  components: {
    BaseLayout
  }
}
</script>
