<template>
    <div
      :tabindex="tabIndex"
      class="main-background">
      <h2>{{ title1 }}<br>{{ title2 }}</h2>
      <ul>
        <li>
          <a
            @keydown.enter="updateSlide(1)"
            @keydown.space="updateSlide(1)"
            @click.prevent="updateSlide(1)"
            >
            <button class="button">
            {{ about }}<span></span>
          </button>
          </a>
        </li>
        <li>
          <a
            @keydown.enter="updateSlide(2)"
            @keydown.space="updateSlide(2)"
            @click.prevent="updateSlide(2)"
            >
            <button class="button">
            {{ play }}<span></span>
          </button>
          </a>
        </li>
        <li>
          <a
            @keydown.enter="updateSlide(3)"
            @keydown.space="updateSlide(3)"
            @click.prevent="updateSlide(3)"
            >
            <button class="button">
            {{ contact }}<span></span>
          </button>
          </a>
        </li>
      </ul>
      <ChangeSlide
        :actualSlide="actualSlide"
        :slideValue="slideValue"
        @keydown.enter="updateSlide()"
        @keydown.space="updateSlide()"
        @click="updateSlide()"/>
    </div>
</template>

<script>
import slideMixin from '@/mixins/slideMethods'

export default {
  name: 'BodyContent',
  mixins: [slideMixin],
  mounted () {
    this.initialAnimation()
  },
  data () {
    return {

    }
  },
  methods: {
    initialAnimation () {
      const timeline = this.gsap.timeline()
      this.gsap.from('.main-background h2', { x: 200, duration: 1, ease: 'expo.out' })
      timeline
        .from('.main-background', { x: -5000, duration: 1.3, ease: 'power4.out' })
        .fromTo('.main-background ul li', { opacity: 0, x: 35 }, { opacity: 1, x: 65, duration: 0.5, stagger: 0.15 })
    }
  },
  computed: {
    tabIndex () {
      return this.actualSlide === this.slideValue ? '1' : '-1'
    },
    lang() {
      return this.$route.meta.lang;
    },
    title1() {
      return this.lang === 'es' ? 'Desafía tu capacidad de ' : 'Table top your';
    },
    title2() {
      return this.lang === 'es' ? 'respuesta a incidentes' : 'incident response skills';
    }, 
    about() {
      return this.lang === 'es' ? '¿Qué es un TTX?' : 'What is a TTX?';
    },
    play() {
      return this.lang === 'es' ? 'Vamos a Jugar!' : 'Let\'s Play!';
    },
    contact() {
      return this.lang === 'es' ? 'Contactanos' : 'Contact us';
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';

.main-background {
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
}

@font-face {
    font-family: 'BalooNano';
    src: url('@/assets/fonts/Baloo2-Regular.ttf');
}

h2 {
  color: #f2f2f3;
  font-size: 4em;
  padding: 2px;
  line-height: .8em;
  margin: 0 auto;
  // margin-right: 200px;
  z-index: 2;
  user-select: none;
  position: absolute;
  top: 10%;
  left: 30%;
  line-height: 65px;
  font-family: "BalooNano", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.black-section {
  background-color:rgba(0, 0, 0, 0);
  height: 100vh;
  width: 410px;
  position: absolute;
  right: -70px;
  transform: rotate(10deg);
  z-index: 1;
}

ul {
  position: absolute;
  list-style: none;
  padding: 0;

  li {
    $cubic-bezier: cubic-bezier(.22,.68,0,1.71);
    $animation-delay: .2s;

    font-weight: 500;
    font-size: 1.5em;
    margin-bottom: 10px;
    white-space: nowrap;
    transition: width .7s $cubic-bezier;
    transition-delay: $animation-delay;
    width: 0;
    max-width: fit-content;

    a {
      text-decoration: none;
      color: #f2f2f3;
      transition: text-shadow .5s ease;
      padding: 0 3px;
      font-size: 1.8em;
    }

    span {
      color: #f2f2f3;
      transition: color .3s ease;
      transition-delay: $animation-delay;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  h2 {
    font-size: 2.8em;
    margin: 0 auto;
    margin-bottom: 20vh;
    position: absolute;
    top: 10%;
    left: 10%;
    line-height: 40px;
    font-family: "BalooNano", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  ul {
    z-index: 1;
    bottom: 20vh;
  }
}

.button {
  border-radius: 30px 30px 30px 30px;
  background-color: #404040;
  border-color: #FFFFFF;
  color: #f2f2f3;
  padding: 14px 35px 14px 35px;
  font-size: 19px;
}

.button:hover {
  border-color: #E1780B;
}

</style>
