import { createRouter, createWebHistory } from 'vue-router';
import App from '@/App.vue';

const routes = [
  {
    path: '/es',
    component: App,
    meta: { lang: 'es' }
  },
  {
    path: '/en',
    component: App,
    meta: { lang: 'en' }
  },
  {
    path: '/',
    redirect: '/es'
  },
  {
    path: '/ccc2024',
    component: App,
    meta: { special: 'ccc' }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
