<template>
  <div
    :tabindex="tabIndex"
    class="slide">
    <h2>{{ title }}<span class="t-black"></span></h2>
    <div>
      <p>
        <a style="color:white" href="mailto:info@BASE4sec.com">info@BASE4sec.com</a>
      </p>
    </div>
    <div class="social-media-link">
      <a href="https://wa.me/message/BFOYHZ3VYQ3HB1" target="_blank" class="item">
        <button class="button" >
        <div  class="figurew">
          <img class="image-main" src="@/assets/img/whatsapp00.png" >
          <img class="image-hover" src="@/assets/img/whatsapp01.png" >
          <br>
        </div>
      </button>
      </a>
      <a href="https://www.linkedin.com/company/BASE4-security/" target="_blank" class="item">
        <button class="button" >
        <div class="figurel">
          <img class="image-main" src="@/assets/img/linkedin00.png">
          <img class="image-hover" src="@/assets/img/linkedin01.png">
          <br>
        </div>
      </button>
      </a>
    </div>
    <div class="disclaimer">
      {{ message }}
    </div>
    <ChangeSlide
      :actualSlide="actualSlide"
      :slideValue="slideValue"
      :direction="'up'"
      @keydown.enter="updateSlide('prev')"
      @keydown.space="updateSlide('prev')"
      @click="updateSlide('prev')"/>
  </div>
</template>

<script>
import slideMixin from '@/mixins/slideMethods'
export default {
  name: 'ContactSlide',
  mixins: [slideMixin],
  components: {

  },
  data () {
    return {
      socialLink: [
        {
          title: 'WhatsApp',
          url: 'https://wa.me/message/BFOYHZ3VYQ3HB1',
          icon: 'WhatsAppIcon'
        },
        {
          title: 'LinkedIn',
          url: 'https://www.linkedin.com/company/BASE4-security/',
          icon: 'LinkedinIcon'
        }
      ]
    }
  },
  computed: {
    tabIndex () {
      return this.actualSlide === this.slideValue ? '1' : '-1'
    },
    lang() {
      return this.$route.meta.lang;
    },
    title() {
      return this.lang === 'es' ? 'Contactanos' : 'Contact us';
    },
    message() {
      return this.lang === 'es' ? 'Gracias' : 'Thank you';
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';

p {
  margin-bottom: 5em;
  padding: 0 15px 0 15px;
  text-align: center !important;

}

.slide h2 {
  font-size: 3em;
  font-family: 'Baloo 2';
  font-size: 1.6em !important;
}

.slide a {
  font-size: 1.6em !important;
}

.social-media-link {
  margin: 0 auto;
}

.item {
  // background: var(--orange);
  width: 13em;
  height: 15em;
  padding: 2em;
  text-align: center;
  margin: 0 1em;
  transition: transform .2s ease;
  text-decoration: none;

  &:hover {
    transform: scale(1.05);
  }

  > div {
    color: var(--white);
    width: 100%;
    height: fit-content;

    svg {
      width: 3em;
      margin-bottom: 2em;
    }

    label {
      font-size: 1em;
      font-weight: bold;
    }
  }
}

.button {
  background-color: #00000000; 
  border-color: #00000000;
  border-radius: 80%;
  color: #f2f2f3;
  padding: 14px 35px 14px 35px;
  font-size: 14px;
}

img {
  max-width: 50%;

}

.figure {
    position: relative;
    max-width: 100%;
  }

.image-hover {
  max-width: 50%;
  position: absolute;
  top: 0;
  right: 0;
  left: 33px;
  bottom: 0;
  object-fit: contain;
  opacity: 0;
  transition: opacity .2s;
}

.figure:hover .image-hover {
    opacity: 1;
  }

@media screen and (max-width: $tablet-breakpoint) {
  .button {
    padding: 0 15px 0 15px;
    font-size: 12px;
  }
  .social-media-link {
    //flex-wrap: wrap;

    a {
      width: 100%;
      height: fit-content;
      padding: .5em;
      margin-bottom: 1em;
      margin: 0;

      &:hover {
        transform: none;
      }

      div {
        display: flex;
        align-items: center;

        svg {
          margin: 0;
          margin-right: 1em;
          width: 3em;
        }
      }
    }
  }
  .slide {
  .disclaimer {
      left: 0 !important;
      }
  h2 {
      padding: 0;
      margin: 0 auto;
      font-size: 1.2em !important;
   } 
   p {
    padding: 5% 10%;
   }
   a {
    font-size: 1.2em !important;
   }
  }

  img {
    //max-width: 30%;
    margin: 0 auto  
  }

  .image-hover {
  left: 0;
}

}

.slide {
  .disclaimer {
      left: 48%;
      }
  }

</style>
