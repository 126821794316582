<template>
  <div
    class="d-flex navbar-container">
    <div class="d-flex container">
      <a href="https://base4sec.com/" target=”_blank” >
      <img src="logo.png" style="max-height: 75px;" alt="SVG Image">
      </a>
      <div>
      <a href="en" class="lang">
        <h2> EN &nbsp;</h2>
      </a>
      <h2 class="lang">| &nbsp;</h2>
      <a href="es" class="lang">
        <h2>ES </h2>
      </a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NavBar'
}
</script>

<style scoped>
.navbar-container {
  min-height: var(--menu-height);
  max-height: var(--menu-height);
}

.container {
  align-items: center;
  justify-content: space-between;
}

.language-controls a {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
}

.lang {
  color: #f2f2f3;
  text-decoration: none;
  display: inline-block;
}
</style>
