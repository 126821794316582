<template>
  <div class="b-black t-white main-container">
    <NavBar/>
    <BodyContent/>
    <ContentFooter/>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar/NavBar'
import BodyContent from '@/components/BodyContent/BodyContent'
import ContentFooter from '@/components/Footer/ContentFooter'

export default {
  name: 'BaseLayout',
  components: {
    NavBar,
    BodyContent,
    ContentFooter
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';

.main-container {
  background-image: url("@/assets/img/background.jpg");
  background-size: cover;
  height: 100vh;
}

.b-black {
  background-color: var(--white);
}

@media screen and (max-width: $tablet-breakpoint) {
  .main-container {
    background-image: url("@/assets/img/backgroundvertical.jpg");
  }
}

</style>
